"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.gameMaterialClick = void 0;
function gameMaterialClick() {
    return {
        link: ($scope, $element) => {
            $element.on('click', function (e) {
                var circle, d, x, y;
                const element = $element[0];
                circle = element.querySelector('.md-click-circle');
                if (!circle) {
                    circle = document.createElement('span');
                    circle.className = 'md-click-circle';
                    element.prepend(circle);
                }
                circle.classList.remove("md-click-animate");
                if (!circle.style.height && !circle.style.width) {
                    d = Math.max(window.outerWidth, window.outerHeight);
                    circle.style.height = `${d}px`;
                    circle.style.width = `${d}px`;
                }
                x = e.pageX - element.offsetLeft - circle.clientWidth / 2;
                y = e.pageY - element.offsetTop - circle.clientWidth / 2;
                circle.style.top = `${y}px`;
                circle.style.left = `${x}px`;
                circle.classList.add('md-click-animate');
            });
        }
    };
}
exports.gameMaterialClick = gameMaterialClick;
